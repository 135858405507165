import { createApp } from 'vue'
import App from './App.vue'
import L from "leaflet";
import { Icon } from 'leaflet';
import "leaflet/dist/leaflet.css";
import 'bootstrap-grid/dist/grid.min.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

createApp(App).mount('#app')
