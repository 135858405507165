<template>
  <div>
    <div id="mapContainer" class="map"></div>

    <div class="content-container">
      <div class="card" :style="cardStyles">
        <template v-for="content in contents" :key="content.id">
          <template v-if="content.type_name === 'h1' || content.type_name === 'h2'">
            <h1 v-if="content.type_name === 'h1'" :style="{ color: cardStyles.textColor, fontSize: cardStyles.h1Size }">{{ content.content }}</h1>
            <h2 v-if="content.type_name === 'h2'" :style="{ color: cardStyles.textColor, fontSize: cardStyles.h2Size }">{{ content.content }}</h2>
          </template>
          <template v-else-if="content.type_name === 'headline' || content.type_name === 'txt'">
            <template v-if="content.type_name === 'headline'">
              <b :style="{ color: cardStyles.textColor }">{{ content.content }}</b>
            </template>
            <template v-else-if="content.type_name === 'txt'">
              <p :style="{ color: cardStyles.textColor }">{{ content.content }}</p>
            </template>
          </template>
          <template v-else-if="content.type_name === 'border-radius' || content.type_name === 'top' || content.type_name === 'left' || content.type_name === 'background-color'">
            <!-- Process the elements and attributes based on the content type as needed -->
          </template>
        </template>
        <div class="subpages-container">
          <div v-for="subpage in subpages" :key="subpage.id">
            <a href="#" @click="selectSubpage(subpage.id)">{{ subpage.name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { config } from '@/config';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GeoJSON } from 'leaflet';

export default {
  name: 'webmap',
  props: {
    pageId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      contents: [],
      subpages: [],
      map: null,
	  geometries: [],
    };
  },
  mounted() {
    this.fetchContents();
    this.fetchSubPages();
  },
  watch: {
    pageId() {
      this.fetchContents();
      this.fetchSubPages();
    },
  },
computed: {
  cardStyles() {
    const borderRadiusData = this.contents.find(content => content.type_name === 'border-radius');
    const backgroundColorData = this.contents.find(content => content.type_name === 'background-color');
    const widthData = this.contents.find(content => content.type_name === 'width');
    const textColorData = this.contents.find(content => content.type_name === 'text-color');
    const topData = this.contents.find(content => content.type_name === 'top');
    const leftData = this.contents.find(content => content.type_name === 'left');
    const h1SizeData = this.contents.find(content => content.type_name === 'h1-size');
    const h2SizeData = this.contents.find(content => content.type_name === 'h2-size');

    const borderRadius = borderRadiusData ? borderRadiusData.content : '25px';
    const backgroundColor = backgroundColorData ? backgroundColorData.content : 'white';
    const width = widthData ? widthData.content : '40%';
    const textColor = textColorData ? textColorData.content : 'black';
    const top = topData ? topData.content : '50%';
    const left = leftData ? leftData.content : '25%';
    const h1Size = h1SizeData ? h1SizeData.content : '1.5rem';
    const h2Size = h2SizeData ? h2SizeData.content : '1.25rem';

    return {
      borderRadius,
      backgroundColor,
      width,
      color: textColor,
      top,
      left,
      h1Size,
      h2Size,
    };
  },
},
  methods: {
    fetchContents() {
      axios
        .get(`https://geotelpical.com/backend/api/web/page/${this.pageId}/contents`, {
          headers: { Authorization: `Bearer ${config.token}` },
        })
        .then(response => {
          this.contents = response.data;
          this.setupMap();
		  this.fetchGeometries();
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchSubPages() {
      axios
        .get(`https://geotelpical.com/backend/api/web/page/${this.pageId}/subpages`, {
          headers: { Authorization: `Bearer ${config.token}` },
        })
        .then(response => {
          this.subpages = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    selectSubpage(subpageId) {
      this.$emit('changePage', subpageId);
    },
    setupMap() {
	
      if (this.map) {
        this.map.remove();
      }

      const coordXData = this.contents.find(content => content.type_name === 'coord_x');
      const coordYData = this.contents.find(content => content.type_name === 'coord_y');
      const zoomLevelData = this.contents.find(content => content.type_name === 'zoom_level');
      const tileServiceData = this.contents.find(content => content.type_name === 'tileservice');
	  const wmsLayerData = this.contents.filter(content => content.type_name === 'WMS');
	  const layerControlData = this.contents.find(content => content.type_name === 'layer_control');

      const coordX = coordXData ? parseFloat(coordXData.content) : 0;
      const coordY = coordYData ? parseFloat(coordYData.content) : 0;
      const zoomLevel = zoomLevelData ? parseInt(zoomLevelData.content) : 0;
      const tileService = tileServiceData ? tileServiceData.content : '';
	  const layercontrol = layerControlData ? layerControlData.content : 'off';

      this.map = L.map('mapContainer', { zoomControl: false });
      this.map.setView([coordY, coordX], zoomLevel);
      L.tileLayer(tileService, { subdomains: 'abcd', ext: 'jpg' }).addTo(this.map);

      document.getElementsByClassName('leaflet-control-attribution')[0].style.display = 'none';

		const wmsLayers = [];
		wmsLayerData
			.sort((a, b) => a.order - b.order)
			.forEach(content => {
				const { url, options } = JSON.parse(content.content);
				const wmsLayer = L.tileLayer.wms(url, options).addTo(this.map);
				wmsLayers.push({ layer: wmsLayer, name: options.name });
				console.log(options.name);
		});
		if (wmsLayers.length > 0 && layercontrol === 'on') {
			const layerControl = L.control.layers(null, null, { collapsed: false, position: 'bottomright' });
			wmsLayers.forEach(layer => {
			layerControl.addOverlay(layer.layer, layer.name);
			});
			layerControl.addTo(this.map);
		}

    },
	fetchGeometries() {
	  axios
		.get(`https://geotelpical.com/backend/api/web/geometries/${this.pageId}`)
		.then(response => {
		  this.geometries = response.data;
		  this.displayGeometries();
		})
		.catch(error => {
		  console.log(error);
		});
	},
	displayGeometries() {
	  const geoJSONFeatures = this.geometries.map(geometry => JSON.parse(geometry.feature));
	  const geoJSONLayer = L.geoJSON(geoJSONFeatures);
	  geoJSONLayer.addTo(this.map);
	},
  },
};
</script>

<style>
#mapContainer {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  z-index: -1;
}

.content-container {
  position: relative;
  z-index: 1;
  padding: 20px;
  user-select: none;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.card {
  position: absolute;
  user-select: none;
  padding: 10px;
  margin-bottom: 10px;
}

.subpages-container {
  position: relative;
  z-index: 1;
  padding: 20px;
}
</style>