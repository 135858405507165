<template>
  <div class="websocial">
    <span class="text">Follow us on &nbsp;</span>
	<a href="https://www.facebook.com" target="_blank" class="social-link">
      <span class="text">Facebook</span>
    </a>
  </div>
</template>

<style>
.websocial {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
  display: flex;
  align-items: center;
  font-size: 12px;
  user-select: none;
}

.social-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

.text {
  white-space: nowrap;
  color: #fff;
}

</style>
