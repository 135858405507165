<template>
  <div class="weblogo">
    <svg @click="reloadPage" class="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 50">
      <text x="0" y="35" font-size="28" font-family="TitilliumWeb-Bold" font-weight="bold" fill="#fff">GEOTELPICAL</text>
    </svg>
  </div>
</template>

<style>
.weblogo {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 9999;
  cursor: pointer;
}

.logo {
  width: 200px;
  height: 50px;
}

@font-face {
  font-family: 'TitilliumWeb-Bold';
  src: url('@/fonts/titilliumweb-bold.ttf') format('truetype');
  /* Add more src declarations for other font file formats if available */
}

</style>

<script>
export default {
  methods: {
    reloadPage() {
      location.reload();
    }
  }
};
</script>
