<template>
  <div>
    <div class="navbar">
      <div class="navbar-toggle" @click="toggleOverlay">
        <span v-show="!showOverlay">☰</span>
        <span v-show="showOverlay">×</span>
      </div>
    </div>
    <div class="overlay" v-show="showOverlay">
      <ul>
        <li v-for="page in pages" :key="page.id" @click="changePage(page.id)">
          {{ page.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
.navbar {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
}

.navbar-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  font-size: 25px;
  color: #000;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay ul {
  list-style-type: none;
  padding: 0;
}

.overlay li {
  margin-bottom: 10px;
  font-size: 55px;
  color: #000;
}

.overlay li:hover {
  cursor: pointer;
  font-weight: bold;
}
</style>

<script>
import axios from 'axios';
import { config } from '@/config';

export default {
  name: 'webmenu',
  data() {
    return {
      pages: [],
      showOverlay: false,
    };
  },
  mounted() {
    this.fetchPages();
  },
  methods: {
    fetchPages() {
      axios
        .get('https://geotelpical.com/backend/api/web/pages', {
          headers: { Authorization: `Bearer ${config.token}` },
        })
        .then((response) => {
          this.pages = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },
    changePage(pageId) {
      this.toggleOverlay();
      this.$emit('change-page', pageId);
    },
  },
};
</script>
