<template>
        <weblogo />
     
        <webmenu @change-page="setPageId" />
      
            <webmap @change-page="setPageId" :page-id="pageId" />
         
            <websocial />
          
</template>

<script>
import weblogo from '@/components/weblogo.vue';
import webmenu from '@/components/webmenu.vue';
import webmap from '@/components/webmap.vue';
import websocial from '@/components/websocial.vue';
import 'bootstrap-grid/dist/grid.min.css';

export default {
  name: 'app',
  components: {
    weblogo,
	webmenu,
    webmap,
	websocial,
  },
  data() {
    return {
      pageId: 1,
    };
  },
  methods: {
    setPageId(pageId) {
      this.pageId = pageId;
    },
  },
};
</script>
